import { FC } from 'react';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Text } from 'modules/theme/components/text/Text';
import MappinIcon from 'modules/theme/components/icons/basic/Mappin.svg';
import { Box } from '@mui/material';
interface ILocation {
    location: string
}

export const Location: FC<ILocation> = ({ location }) => {
    return (
        <Box component='div' display='flex' alignItems='center' data-test-id='location' gap={1}>
            <Svg icon component={MappinIcon} width={16}/>
            <Text variant='body2'>{location}</Text>
        </Box>
    );
};
