import { FC, useMemo } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/navigation';
import { REAL_ESTATE_AGENCIES_LISTING } from 'modules/route/routes';
import { Box, Container, Theme, useMediaQuery } from '@mui/material';
import { IAgency } from 'modules/realEstate/agency/listing/interfaces/agency/IAgency';
import { Filter } from 'components/page/realEstate/agency/listing/components/search/filter/Filter';
import { searchDefaultLimit } from 'modules/realEstate/agency/listing/constants/searchDefaultLimit';
import { IRealEstateAgencyFilter } from 'modules/realEstate/agency/listing/interfaces/IRealEstateAgencyFilter';
import { Listing as SearchResultListing } from 'components/page/realEstate/agency/listing/components/search/result/listing/Listing';
import { IAgenciesListSearchFormFilter } from 'components/page/realEstate/agency/listing/interfaces/IAgenciesListSearchFormFilter';
import { IRealEstateListingLocation } from 'modules/realEstate/common/RealEstateAgencyListingLocations/interfaces/IRealEstateListingLocation';
import { IProfiAgency } from 'modules/realEstate/common/interfaces/IProfiAgency';
import { Breadcrumbs } from 'components/breadcrumbs/Breadcrumbs';
import { IBreadcrumb } from 'components/breadcrumbs/interfaces/IBreadcrumb';

const CarouselSlider = dynamic(
    () =>
        import('./carouselSlider/CarouselSlider').then(
            (component) => component.CarouselSlider
        ),
    {
        ssr: false,
    }
);

interface ISearchResult {
    agencies: IAgency[];
    totalAgenciesCount: number;
    filter: IRealEstateAgencyFilter;
    allLocations?: IRealEstateListingLocation[];
    currentlySelectedLocation: IRealEstateListingLocation | null;
    initialProfiAgencies: IProfiAgency[];
    breadcrumbs: IBreadcrumb[];
}

export const Search: FC<ISearchResult> = ({
    filter,
    agencies,
    totalAgenciesCount,
    currentlySelectedLocation,
    initialProfiAgencies,
    breadcrumbs,
}) => {
    const router = useRouter();
    const totalPages = Math.ceil(totalAgenciesCount / searchDefaultLimit);
    const currentPage = (filter.skip / searchDefaultLimit || 0) + 1;
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    const initialSearchFormFilter =
        useMemo((): IAgenciesListSearchFormFilter => {
            return {
                selectedLocationId: currentlySelectedLocation?.id,
                searchedAgencyName: filter.searchNameTerm,
                allOtherLocationsExceptTheSelected:
                    filter.allOtherLocationsExceptTheSelected,
            };
        }, [
            currentlySelectedLocation,
            filter.allOtherLocationsExceptTheSelected,
            filter.searchNameTerm,
        ]);

    return (
        <>
            <Container>
                <Box
                    pb={2.5}
                    borderBottom={(theme: Theme) =>
                        `1px solid ${theme.palette.dividerTransparent}`
                    }
                >
                    <Filter initialFilter={initialSearchFormFilter} />
                </Box>

                <Box mb={5}>
                    <Breadcrumbs items={breadcrumbs} />
                </Box>
            </Container>

            {agencies.length > 0 && (
                <Container
                    maxWidth='lg'
                    disableGutters={isMobile}
                    sx={{ paddingLeft: { xs: 2 }}}
                >
                    <Box mb={3}>
                        <CarouselSlider
                            initialProfiAgencies={initialProfiAgencies}
                        />
                    </Box>
                </Container>
            )}

            <Container sx={{ paddingBottom: 5 }}>
                <Box>
                    <SearchResultListing
                        agencies={agencies}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        onResetFilterClick={() => {
                            router.push(REAL_ESTATE_AGENCIES_LISTING());
                        }}
                        searchNameTerm={filter?.searchNameTerm}
                        currentlySelectedLocation={currentlySelectedLocation}
                    />
                </Box>
            </Container>
        </>
    );
};
