import { FC } from 'react';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { lemmatizeWordAccordingToNumber } from 'modules/lemmatize/lemmatizeWordAccordingToNumber';
import { ILemmatizeWordAccordingToNumber } from 'modules/lemmatize/ILemmatizeWordAccordingToNumber';
import { AvatarGroupImgs } from 'modules/theme/components/agency/common/components/avatarGroupImgs/AvatarGroupImgs';


interface IAgentsInfo {
    numberOfTotalActiveAgents: number;
    agentsPreviewPhotosUrl?: string[]
}

export const AgentsInfo: FC<IAgentsInfo> = ({ numberOfTotalActiveAgents, agentsPreviewPhotosUrl }) => {

    const lemmatizeWords: ILemmatizeWordAccordingToNumber = {
        eqZero: 'bez maklérov',
        eqOne: 'maklér',
        betweenTwoAndFourInclusive: 'makléri',
        moreThenFour: 'maklérov',
        withoutNumberIfZero: true,
    };

    const totalAgentsString = lemmatizeWordAccordingToNumber(numberOfTotalActiveAgents, lemmatizeWords);

    return <Box display='flex' gap={numberOfTotalActiveAgents ? 1 : 0} alignItems='center' data-test-id='agentsInfo'>

        <AvatarGroupImgs
            maxShowingImgs={3}
            spacingBetweenImgs={12}
            imgsUrls={agentsPreviewPhotosUrl}
        />

        <Text variant='body3' semibold>{totalAgentsString}</Text>

    </Box>;
};
