import { RealEstateAgencyListingAgencyDto } from 'modules/api/generated';
import { IAgency } from 'modules/realEstate/agency/listing/interfaces/agency/IAgency';

export const mapAgencyDtoToAgencyInterface = (agenciesDto: RealEstateAgencyListingAgencyDto[]): IAgency[] => {
    return (agenciesDto || []).map((agencyDto) => {
        const agencyAddress = agencyDto.agencyAddress;
        const agencyInfo = agencyDto.agentsInfo;
        const agents = (agencyInfo.agents || []).map((agent) => ({
            userId: agent.userId,
            previewPhotoUrl: agent.previewPhotoUrl,
        }));
        const advertisementsCountInfo = agencyDto.advertisementsInfo.advertisementsCountInfo;
        const advertisementsPhotos = (agencyDto.advertisementsInfo.advertisementsPhotos || []).map((photo) => ({
            urls: photo.urls,
            advertisementId: photo.advertisementId,
        }));

        return {
            isProfi: agencyDto.isProfi,
            isCentralBranch: agencyDto.isCentralBranch,
            agencyName: agencyDto.agencyName,
            agencySlugName: agencyDto.agencySlugName,
            parentAgencySlugName: agencyDto.parentAgencySlugName,
            agencyId: agencyDto.agencyId,
            agencyLogoUrl: agencyDto.agencyLogoUrl,
            agencyAddress: {
                county: agencyAddress?.county || '',
                city: agencyAddress?.city || '',
                region: agencyAddress?.region || '',
                street: agencyAddress?.street || '',
                country: agencyAddress?.country || '',
                streetNumber: agencyAddress?.streetNumber || '',
            },
            agentsInfo: {
                numberOfTotalActiveAgents: agencyInfo.numberOfTotalActiveAgents,
                agents,
            },
            advertisementsInfo: {
                advertisementsCountInfo: {
                    numberOfTotalForSale: advertisementsCountInfo.numberOfTotalForSale,
                    numberOfTotalForRent: advertisementsCountInfo.numberOfTotalForRent,
                },
                advertisementsPhotos,
            },
            createdAt: agencyDto.createdAt || null,
            updatedAt: agencyDto.updatedAt || null,
        };});

};
