import { FC, ReactNode } from 'react';
import Link from 'components/link/Link';
import { Box } from '@mui/material';

interface AgencyDetailLinkWrapper {
    children: ReactNode;
    detailUrl: string;
    onClick?: () => void;
}

export const AgencyDetailLinkWrapper: FC<AgencyDetailLinkWrapper> = ({
    children,
    detailUrl,
    onClick,
}) => {
    return (
        <Box component='span' data-test-id='agencyDetailLinkWrapper'>
            <Link
                noUnderline
                href={detailUrl}
                onClick={onClick}
            >
                {children}
            </Link>
        </Box>
    );
};
