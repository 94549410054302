import { FC } from 'react';
import { Grid } from '@mui/material';
import { Button } from 'modules/theme/components/button/Button';
import {
    ERealEstateListingSubpages
} from 'components/page/realEstate/common/enums/ERealEstateListingSubpages';
import { DesktopFilter } from 'components/page/realEstate/agency/listing/components/search/filter/partials/DesktopFilter';
import { MobileFilter } from 'components/page/realEstate/agency/listing/components/search/filter/partials/MobileFilter';

import {
    AgencyAgentSwitcher
} from 'components/page/realEstate/common/components/agencyAgentSwitcher/AgencyAgentSwitcher';
import { IAgenciesListSearchFormFilter } from 'components/page/realEstate/agency/listing/interfaces/IAgenciesListSearchFormFilter';
import { useAgencyListingSearchFormFilter } from 'components/page/realEstate/agency/listing/hooks/agencyListingSearchFormFilter/useAgencyListingSearchFormFilter';

interface IFilter {
    initialFilter: IAgenciesListSearchFormFilter;
}

export const Filter: FC<IFilter> = ({
    initialFilter,
}) => {
    const {
        submit,
        locations,
        setSubpage,
        selectedLocation,
        searchedAgencyName,
        setSelectedLocation,
        areLocationsLoading,
        totalCountOfAgencies,
        setSearchedAgencyName,
    } = useAgencyListingSearchFormFilter(initialFilter);

    return (
        <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} md={4}>
                <AgencyAgentSwitcher
                    onSelect={setSubpage}
                    currentSubpage={ERealEstateListingSubpages.AGENCY}
                />
            </Grid>

            <Grid item xs={12} md={4} display={{ xs: 'flex', md: 'none' }}>
                <MobileFilter
                    submit={submit}
                    locations={locations}
                    selectedLocation={selectedLocation}
                    setSelectedLocation={setSelectedLocation}
                    areLocationsLoading={areLocationsLoading}
                    searchedAgencyName={searchedAgencyName}
                    setSearchedAgencyName={setSearchedAgencyName}
                />
            </Grid>

            <Grid container item xs={12} md={6} spacing={2} display={{ xs: 'none', md: 'flex' }}>
                <DesktopFilter
                    submit={submit}
                    locations={locations}
                    selectedLocation={selectedLocation}
                    setSelectedLocation={setSelectedLocation}
                    areLocationsLoading={areLocationsLoading}
                    searchedAgencyName={searchedAgencyName}
                    setSearchedAgencyName={setSearchedAgencyName}
                />
            </Grid>

            <Grid item xs={6} sm={5} md={2}>
                <Button
                    fullWidth
                    onClick={submit}
                    text={`Hľadať (${totalCountOfAgencies})`}
                />
            </Grid>

        </Grid>
    );
};
