import { useDebouncedCallback } from 'use-debounce';
import { useCallback, useEffect, useState } from 'react';
import { realEstateAgencyListingApi } from 'modules/api/client';

interface IReturn {
    isFetching: boolean;
    totalCountOfAgencies: number;
}

export const useAgenciesTotalCountByFilter = (agencyName: string, locationIds: string[], allOtherLocationsExceptTheSelected: boolean): IReturn => {
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [totalCountOfAgencies, setTotalCountOfAgencies] = useState<number>(0);

    const getTotalCountOfAgencies = useCallback(async () => {
        try {
            setIsFetching(true);
            const response = await realEstateAgencyListingApi.getTotalRealEstateAgencyListing(
                (locationIds || []).join(',') || undefined,
                allOtherLocationsExceptTheSelected,
                agencyName
            );

            setTotalCountOfAgencies(response.data);
        } catch (error) {
            setTotalCountOfAgencies(0);
        } finally {
            setIsFetching(false);
        }
    }, [agencyName, locationIds, allOtherLocationsExceptTheSelected]);

    const debouncedTotalCountFetch = useDebouncedCallback(getTotalCountOfAgencies, 250);


    useEffect(() => {
        (async () => {
            await debouncedTotalCountFetch();
        })();
    }, [debouncedTotalCountFetch, getTotalCountOfAgencies]);


    return {
        isFetching,
        totalCountOfAgencies,
    };
};
