import { AxiosResponse } from 'axios';
import { RealEstateAgencyListingAgencyDto } from 'modules/api/generated';
import { realEstateAgencyListingApi } from 'modules/api/client';

const fetchRelatedAgencies = async (locationIds: string[]): Promise<RealEstateAgencyListingAgencyDto[]> => {
    let response: AxiosResponse<RealEstateAgencyListingAgencyDto[]>;

    try {
        response = await realEstateAgencyListingApi.getRelatedRealEstateAgencyListing(
            locationIds,
        );
    } catch (error) {
        return [];
    }

    if (response.status !== 200 || !response.data) {
        return [];
    }

    return response.data;
};

export default fetchRelatedAgencies;
