import { FC } from 'react';
import { Box } from '@mui/material';
import { CardTitle as Title } from 'modules/theme/components/agency/common/components/cardTitle/CardTitle';
import {
    Location
} from 'modules/theme/components/agency/agency/listingCard/partials/agencyInfo/partials/location/Location';
import {
    ProfiBadge
} from 'modules/theme/components/agency/agency/listingCard/partials/agencyInfo/partials/profiBadge/ProfiBadge';
import { getLocationString } from 'modules/theme/components/agency/agency/listingCard/helpers/getLocationString';


interface IAgencyInfo {
    city?: string;
    street?: string;
    region?: string;
    isProfi?: boolean;
    agencyName: string;
    streetNumber?: string;
}

export const AgencyInfo: FC<IAgencyInfo> = ({
    city,
    street,
    region,
    isProfi,
    agencyName,
    streetNumber
}) => {
    const location = getLocationString(city, street, region, streetNumber);
    return <Box
        gap={2}
        display='flex'
        alignItems={{ xs: 'flex-start', md: 'center' }}
        justifyContent={{ xs: 'space-between', md: 'flex-start' }}
        flexDirection={{ xs: 'row-reverse', md: 'row' }}
        data-test-id='agencyInfo'
    >
        {isProfi &&
            <Box>
                <ProfiBadge/>
            </Box>
        }

        <Box>
            <Title title={agencyName}/>
            {location && (
                <Box display='flex'
                    gap={.3}
                    alignItems={{ xs: 'baseline', md: 'center' }}
                >
                    <Location location={location}/>
                </Box>
            )}
        </Box>
    </Box>;

};
