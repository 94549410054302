import { FC } from 'react';
import { Input } from 'modules/theme/components/fields/input/Input';
import Close from 'modules/theme/components/icons/basic/Close.svg';
import {
    Location
} from 'components/page/realEstate/common/agencyAndAgentListing/components/searchFormFilter/location/Location';
import { InputsMergedWrapper } from 'modules/theme/components/fields/input/partials/InputsMergedWrapper';
import { ILocation } from 'components/page/realEstate/common/interfaces/ILocation';

interface IMobileFilter {
    submit: () => void;
    locations: ILocation[];
    selectedLocation: ILocation;
    areLocationsLoading: boolean;
    searchedAgencyName: string;
    setSearchedAgencyName: (searchedName: string) => void;
    setSelectedLocation: (selectedLocationId: string) => void;
}

export const MobileFilter: FC<IMobileFilter> = ({
    submit,
    locations,
    selectedLocation,
    setSelectedLocation,
    areLocationsLoading,
    searchedAgencyName,
    setSearchedAgencyName
}) => {

    return <InputsMergedWrapper>
        <Location
            locations={locations}
            isLoading={areLocationsLoading}
            selectedLocation={selectedLocation}
            onSelectedLocation={setSelectedLocation}
        />
        <Input
            onEnterPress={submit}
            autoComplete='off'
            value={searchedAgencyName}
            onChange={setSearchedAgencyName}
            endIcon={searchedAgencyName && Close}
            placeholder='Názov realitnej kancelárie ...'
            onEndIconClick={() => setSearchedAgencyName('')}
        />
    </InputsMergedWrapper>;
};