import { useEffect, useState } from 'react';
import { IAgency } from 'modules/realEstate/agency/listing/interfaces/agency/IAgency';
import fetchRelatedAgencies from 'components/page/realEstate/agency/listing/hooks/related/api/fetchRelatedAgencies';
import useLoading from 'modules/stores/loading/useLoading';
import { mapAgencyDtoToAgencyInterface } from 'modules/realEstate/agency/listing/mappers/agencyDtoToAgencyInterfaceMapper';

interface IReturn {
    agencies: IAgency[];
    isFetching: boolean;
}

export const useRelatedAgencies = (locationIds: string[]): IReturn => {
    const [ isFetching, setIsFetching ] = useState<boolean>(false);
    const [ agencies, setAgencies ] = useState([]);
    const { start: startLoading, stop: stopLoading } = useLoading();

    useEffect(() => {
        if (!locationIds || !locationIds.length) {
            return;
        }

        let isActive = true;

        (async () => {
            try {
                setIsFetching(true);
                startLoading();
                const data = await fetchRelatedAgencies(locationIds);

                if (!data) {
                    setIsFetching(false);
                    stopLoading();
                    return;
                }

                if (isActive) {
                    setIsFetching(false);
                    stopLoading();
                    setAgencies(mapAgencyDtoToAgencyInterface(data));
                }
            } catch (e) {
                setIsFetching(false);
                stopLoading();
            }
        })();

        return () => {
            isActive = false;
        };
    }, [locationIds, startLoading, stopLoading]);

    return {
        agencies,
        isFetching,
    };
};
