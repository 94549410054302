import { FC } from 'react';
import { Grid } from '@mui/material';
import { Input } from 'modules/theme/components/fields/input/Input';
import Close from 'modules/theme/components/icons/basic/Close.svg';
import {
    Location
} from 'components/page/realEstate/common/agencyAndAgentListing/components/searchFormFilter/location/Location';
import { ILocation } from 'components/page/realEstate/common/interfaces/ILocation';

interface IDesktopFilter {
    submit: () => void;
    locations: ILocation[];
    selectedLocation: ILocation;
    areLocationsLoading: boolean;
    searchedAgencyName: string;
    setSearchedAgencyName: (searchedName: string) => void;
    setSelectedLocation: (selectedLocationId: string) => void;
}

export const DesktopFilter: FC<IDesktopFilter> = ({
    submit,
    locations,
    selectedLocation,
    setSelectedLocation,
    areLocationsLoading,
    searchedAgencyName,
    setSearchedAgencyName
}) => {

    return <>
        <Grid item md={6}>
            <Location
                locations={locations}
                isLoading={areLocationsLoading}
                selectedLocation={selectedLocation}
                onSelectedLocation={setSelectedLocation}
            />
        </Grid>
        <Grid item md={6}>
            <Input
                onEnterPress={submit}
                value={searchedAgencyName}
                onChange={setSearchedAgencyName}
                endIcon={searchedAgencyName && Close}
                autoComplete='off'
                placeholder='Názov realitnej kancelárie ...'
                onEndIconClick={() => setSearchedAgencyName('')}
            />
        </Grid>
    </>;
};