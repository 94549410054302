import { FC } from 'react';
import { Paginator } from 'modules/theme/components/paginator/Paginator';
import { usePagination } from 'components/page/realEstate/agency/listing/hooks/pagination/usePagination';
import { REAL_ESTATE_AGENCIES_LISTING } from 'modules/route/routes';


interface IPagination {
    totalPages: number;
    currentPage: number;
    searchNameTerm?: string;
    locationSefName?: string;
}

export const Pagination: FC<IPagination> = ({ currentPage, totalPages, searchNameTerm, locationSefName }) => {
    const { setPage } = usePagination(currentPage, searchNameTerm, locationSefName);

    return <>
        <Paginator
            showScrollToTopBtn
            onChange={setPage}
            currentPage={currentPage}
            totalPageCount={totalPages}
            itemHref={(page) => REAL_ESTATE_AGENCIES_LISTING(locationSefName, page)}
            prevHref={currentPage > 1 && REAL_ESTATE_AGENCIES_LISTING(locationSefName, currentPage - 1)}
            nextHref={totalPages > currentPage && REAL_ESTATE_AGENCIES_LISTING(locationSefName, currentPage + 1)}
        />
    </>;
};
