import { FC, useEffect } from 'react';
import { Grid } from '@mui/material';
import { IAgency } from 'modules/realEstate/agency/listing/interfaces/agency/IAgency';
import { AgencyListingCard } from 'modules/theme/components/agency/agency/listingCard/AgencyListingCard';
import { REAL_ESTATE_BRANCH_DETAIL_TAB } from 'modules/route/routes';
import { getBranchType } from 'components/page/realEstate/common/helpers/AgencyHelper';
import { ERouterTabs } from 'modules/route/enums/agency/detail/ERouterTabs';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import {
    getAgencySelectItemEvent,
    getViewItemListAgenciesEvent,
    getAgencySelectPromotionEvent,
    getViewItemListUsersPromotionEvent,
} from 'modules/gtmEvents/helpers/events/realEstate/realEstateEventsHelper';
import { getOrderIndex } from 'modules/gtmEvents/helpers/gtmHelper';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import {
    getResetEcommerceEvent
} from 'modules/gtmEvents/helpers/events/common/commonEventsHelper';
import { IGtmEvent } from 'modules/analytics/IAnalytics';

interface IListOfAgencies {
    agencies: IAgency[];
    currentPage: number;
    limit: number;
}

export const ListOfAgencies: FC<IListOfAgencies> = ({ agencies, currentPage, limit }) => {
    const { gtm: { sendEvent: gtmSendEvent }} = useAnalytics();

    useEffect(() => {
        if (!agencies.length) {
            return;
        }

        const events: IGtmEvent[] = [
            getResetEcommerceEvent(),
            getViewItemListAgenciesEvent(agencies, currentPage, limit),
        ];

        const profiAgencies = agencies.filter((agency) => agency.isProfi);
        if (profiAgencies.length) {
            events.push(getViewItemListUsersPromotionEvent(profiAgencies, currentPage, limit));
        }
        gtmSendEvent(...events);
    }, [agencies, currentPage, gtmSendEvent, limit]);

    return (
        <Grid container>
            {agencies.map((agency: IAgency, index) => {
                const {
                    isProfi,
                    agencyId,
                    agencyName,
                    agencyLogoUrl,
                    agencyAddress,
                    agencySlugName,
                    isCentralBranch,
                    agentsInfo: { numberOfTotalActiveAgents, agents },
                    advertisementsInfo: {
                        advertisementsPhotos: advertisementsPhotos,
                        advertisementsCountInfo: { numberOfTotalForSale, numberOfTotalForRent }
                    },
                } = agency;

                const agencyDetailUrl
                    = `${AppConfigService.getNehnutelnostiUrl()}${REAL_ESTATE_BRANCH_DETAIL_TAB(agencyId, agencySlugName, getBranchType(isCentralBranch), ERouterTabs.ABOUT)}`;

                return (
                    <Grid item xs={12} key={`agency-${agencySlugName}`}>
                        <AgencyListingCard
                            isProfi={isProfi}
                            agencyName={agencyName}
                            city={agencyAddress?.city}
                            street={agencyAddress?.street}
                            region={agencyAddress?.region}
                            agencyDetailUrl={agencyDetailUrl}
                            agencyLogoPreviewImgUrl={agencyLogoUrl}
                            advertisementsPhotos={advertisementsPhotos}
                            streetNumber={agencyAddress?.streetNumber}
                            numberOfTotalActiveAgents={numberOfTotalActiveAgents}
                            numberOfTotalAdvertisementForRent={numberOfTotalForRent}
                            numberOfTotalAdvertisementForSale={numberOfTotalForSale}
                            agentsPreviewPhotosUrl={agents.map(agent => agent.previewPhotoUrl)}
                            onClick={() => {
                                const orderIndex = getOrderIndex(index, currentPage, limit);
                                gtmSendEvent(
                                    getResetEcommerceEvent(),
                                    getAgencySelectItemEvent(
                                        agency,
                                        orderIndex,
                                    ),
                                );
                                if (agency.isProfi) {
                                    gtmSendEvent(
                                        getAgencySelectPromotionEvent(
                                            agency,
                                            orderIndex,
                                        )
                                    );
                                }
                            }}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};
