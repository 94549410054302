import React, { FC } from 'react';
import { AvatarGroup, Avatar, useTheme } from '@mui/material';
import Icon from 'modules/theme/components/assets/noPhoto/svg/UserAvatar.svg';


interface IAvatarGroupImgs {
    imgsUrls: string[];
    maxShowingImgs?: number;
    spacingBetweenImgs?: number;
}

export const AvatarGroupImgs: FC<IAvatarGroupImgs> = ({ imgsUrls, maxShowingImgs = 4, spacingBetweenImgs = 18 }) => {
    const { palette } = useTheme();

    return <AvatarGroup max={maxShowingImgs} spacing={spacingBetweenImgs}>
        {imgsUrls.map((imgUrl, index) => {
            if (!imgUrl) return <Avatar
                src={Icon}
                key={index}
                sx={{
                    width: 32,
                    height: 32,
                }}
            />;

            return (
                <Avatar
                    src={imgUrl}
                    key={imgUrl}
                    alt='Foto makléra'
                    sx={{
                        width: 32,
                        height: 32,
                        filter: `drop-shadow(0px 2px 4px ${palette.labelQuarternary.main})`,
                    }}
                />
            );
        })}
    </AvatarGroup>;
};
