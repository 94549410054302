import { FC, useMemo } from 'react';
import { Box } from '@mui/material';
import { IAgency } from 'modules/realEstate/agency/listing/interfaces/agency/IAgency';
import {
    Pagination
} from 'components/page/realEstate/agency/listing/components/search/result/listing/pagination/Pagination';
import {
    ListOfAgencies
} from 'components/page/realEstate/agency/listing/components/search/result/listing/listOfAgencies/ListOfAgencies';
import { NoResult } from 'components/page/realEstate/agency/listing/components/search/result/listing/noResult/NoResult';
import { IRealEstateListingLocation } from 'modules/realEstate/common/RealEstateAgencyListingLocations/interfaces/IRealEstateListingLocation';
import { LIMIT } from 'components/page/realEstate/common/advertisements/constants/limit';

interface IListing {
    totalPages: number;
    agencies: IAgency[];
    currentPage: number;
    searchNameTerm?: string;
    onResetFilterClick: () => void;
    currentlySelectedLocation: IRealEstateListingLocation | null;
}

export const Listing: FC<IListing> = ({
    agencies,
    totalPages,
    currentPage,
    searchNameTerm,
    onResetFilterClick,
    currentlySelectedLocation,
}) => {
    const showListingResult = agencies.length > 0;
    const emptyLocationIds = useMemo(() => [], []);
    const locationIdsLength = currentlySelectedLocation?.locationIds.length || 0;

    return <>
        {showListingResult &&
            <>
                <Box mb={{ xs: 2, md: 5 }}>
                    <ListOfAgencies agencies={agencies} currentPage={currentPage} limit={LIMIT}/>
                </Box>
                <Box>
                    <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        searchNameTerm={searchNameTerm}
                        locationSefName={currentlySelectedLocation?.sefName}
                    />
                </Box>
            </>
        }

        {!showListingResult &&
        <NoResult
            onResetFilterClick={onResetFilterClick}
            locationIds={currentlySelectedLocation?.locationIds || emptyLocationIds}
            showRelated={locationIdsLength > 0 && !currentlySelectedLocation?.isForeign}
        />}
    </>;
};
