'use client';

import { FC } from 'react';
import { Container, Box } from '@mui/material';
import { IBreadcrumb } from 'components/breadcrumbs/interfaces/IBreadcrumb';
import { IAgency } from 'modules/realEstate/agency/listing/interfaces/agency/IAgency';
import { Search } from 'components/page/realEstate/agency/listing/components/search/Search';
import { Headline } from 'components/page/realEstate/common/agencyAndAgentListing/components/headline/Headline';
import { AdditionalServices } from 'components/page/realEstate/common/additionalServices/components/AdditionalServices';

import { IRealEstateAgencyFilter } from 'modules/realEstate/agency/listing/interfaces/IRealEstateAgencyFilter';
import { IRealEstateListingLocation } from 'modules/realEstate/common/RealEstateAgencyListingLocations/interfaces/IRealEstateListingLocation';
import { IProfiAgency } from 'modules/realEstate/common/interfaces/IProfiAgency';
import { usePageViewEvent } from 'modules/gtmEvents/hooks/pageView/usePageViewEvent';

interface IProps {
    agencies: IAgency[];
    totalCount: number;
    breadcrumbs: IBreadcrumb[];
    filter: IRealEstateAgencyFilter;
    locations: IRealEstateListingLocation[];
    selectedLocation: IRealEstateListingLocation | null;
    initialProfiAgencies: IProfiAgency[];
}

export const Main: FC<IProps> = ({
    filter,
    agencies,
    totalCount,
    breadcrumbs,
    selectedLocation,
    initialProfiAgencies,
}) => {
    usePageViewEvent();

    return (
        <>
            <Container>
                <Box mb={3} pt={{ xs: 4, md: 6 }}>
                    <Headline headline='Realitné kancelárie' />
                </Box>
            </Container>

            <Search
                filter={filter}
                agencies={agencies}
                totalAgenciesCount={totalCount}
                currentlySelectedLocation={selectedLocation}
                initialProfiAgencies={initialProfiAgencies}
                breadcrumbs={breadcrumbs}
            />

            <Container maxWidth='lg' disableGutters>
                <Box mb={5} pb={4} pt={5}>
                    <AdditionalServices />
                </Box>
            </Container>
        </>
    );
};
