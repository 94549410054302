import { useCallback } from 'react';
import { useRouter } from 'next/navigation';
import { REAL_ESTATE_AGENCIES_LISTING } from 'modules/route/routes';
import useLoading from 'modules/stores/loading/useLoading';

interface IReturn {
    setPage: (page: number) => void
}

export const usePagination = (currentPage: number, searchNameTerm ?: string, locationSefName ?: string): IReturn => {
    const router = useRouter();
    const { start: startLoading } = useLoading();

    const redirectToPage = useCallback((newPage: number) => {
        const path = REAL_ESTATE_AGENCIES_LISTING(locationSefName);

        const params = new URLSearchParams();
        if (searchNameTerm) {
            params.append('fulltext', searchNameTerm);
        }
        if (newPage && newPage !== 1) {
            params.append('page', newPage.toString());
        }
        const url = `${path}?${params.toString()}`;

        startLoading();
        router.push(url);
    }, [router, searchNameTerm, locationSefName, startLoading]);

    const setPage = useCallback((page: number) => {
        redirectToPage(page);
    }, [redirectToPage]);

    return {
        setPage
    };

};
