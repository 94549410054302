import { FC } from 'react';
import { Box } from '@mui/material';
import Image from 'components/image/Image';
import { Desktop, Mobile } from 'modules/theme/components/responsive';
import BudgeProfiClientDesktop from 'modules/theme/components/agency/agency/common/svg/BudgeProfiClientDesktop.svg?url';
import BudgeProfiClientMobile from 'modules/theme/components/agency/agency/common/svg/BudgeProfiClientMobile.svg?url';

export const ProfiBadge: FC = () => {
    return (
        <Box data-test-id='profiBadge'>
            <Mobile>
                <Image
                    alt=''
                    width={40}
                    height={40}
                    src={BudgeProfiClientMobile}
                />
            </Mobile>
            <Desktop>
                <Image
                    alt=''
                    width={48}
                    height={48}
                    src={BudgeProfiClientDesktop}
                />
            </Desktop>
        </Box>
    );
};
