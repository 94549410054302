import {
    REAL_ESTATE_AGENTS_LISTING,
    REAL_ESTATE_AGENCIES_LISTING,
} from 'modules/route/routes';
import {
    IAgenciesListSearchFormFilter
} from 'components/page/realEstate/agency/listing/interfaces/IAgenciesListSearchFormFilter';
import {
    ERealEstateListingSubpages
} from 'components/page/realEstate/common/enums/ERealEstateListingSubpages';
import {
    useAgenciesTotalCountByFilter
} from 'components/page/realEstate/agency/listing/hooks/agencyListingSearchFormFilter/partials/useAgenciesTotalCountByFilter';

import { useRouter } from 'next/navigation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ILocation } from 'components/page/realEstate/common/interfaces/ILocation';
import { useLocations } from 'components/page/realEstate/common/hooks/useLocations';
import useLoading from 'modules/stores/loading/useLoading';

interface IReturn {
    submit: () => void;
    locations: ILocation[];
    resetFilter: () => void;
    searchedAgencyName: string;
    selectedLocation: ILocation;
    isTotalCountLoading: boolean;
    totalCountOfAgencies: number;
    areLocationsLoading: boolean;
    setSearchedAgencyName: (searchedName: string) => void;
    setSelectedLocation: (selectedLocationId: string) => void;
    setSubpage: (currentSubpage: ERealEstateListingSubpages) => void;
}

export const useAgencyListingSearchFormFilter = (initialFilter: IAgenciesListSearchFormFilter): IReturn => {
    const router = useRouter();
    const { start: startLoading } = useLoading();
    const [currentFilter, setCurrentFilter] = useState<IAgenciesListSearchFormFilter>(initialFilter);

    const {
        locations,
        isFetching: areLocationsLoading,
    } = useLocations();

    useEffect(() => {
        setCurrentFilter(initialFilter);
    }, [initialFilter]);

    const selectedLocation = locations.find(location => location.id === currentFilter.selectedLocationId);

    const selectedLocationIds = useMemo(() => {
        return selectedLocation?.locationIds || [];
    }, [selectedLocation]);

    const {
        totalCountOfAgencies,
        isFetching: isTotalCountLoading,
    } = useAgenciesTotalCountByFilter(currentFilter.searchedAgencyName, selectedLocationIds, !!selectedLocation?.allOtherLocations);

    const setSearchedAgencyName = useCallback((searchedName: string) => {
        setCurrentFilter(oldFilter => ({
            ...oldFilter,
            searchedAgencyName: searchedName,
        }));
    }, []);

    const setSelectedLocation = useCallback((selectedLocationId: string) => {
        setCurrentFilter(oldFilter => ({
            ...oldFilter,
            selectedLocationId: selectedLocationId,
        }));
    }, []);

    const redirectByFilter = useCallback(() => {
        const path = REAL_ESTATE_AGENCIES_LISTING(selectedLocation?.sefName);

        const params = new URLSearchParams();
        if (currentFilter?.searchedAgencyName) {
            params.append('fulltext', currentFilter?.searchedAgencyName);
        }
        const url = `${path}?${params.toString()}`;
        router.push(url);
    }, [currentFilter, selectedLocation, router]);


    const redirectToSubpage = useCallback((currentSubpage: ERealEstateListingSubpages) => {
        startLoading();
        if (currentSubpage === ERealEstateListingSubpages.AGENT) {
            router.push(REAL_ESTATE_AGENTS_LISTING());
        }
    }, [router, startLoading]);

    const onSubmitSearchForm = useCallback(() => {
        startLoading();
        redirectByFilter();
    }, [redirectByFilter, startLoading]);

    const onResetSearchFormFilter = useCallback(() => {
        setCurrentFilter(oldFilter => ({
            ...oldFilter,
            selectedLocation: null,
            searchedAgencyName: null,
        }));
        router.push(REAL_ESTATE_AGENCIES_LISTING());
    }, [router]);

    return {
        locations,
        selectedLocation,
        areLocationsLoading,
        isTotalCountLoading,
        totalCountOfAgencies,
        submit: onSubmitSearchForm,
        setSubpage: redirectToSubpage,
        searchedAgencyName: currentFilter.searchedAgencyName,
        resetFilter: onResetSearchFormFilter,
        setSelectedLocation,
        setSearchedAgencyName,
    };
};
