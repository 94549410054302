import { FC } from 'react';
import { Box, Theme } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import { Button } from 'modules/theme/components/button/Button';
import NoDataImg from 'components/page/realEstate/common/advertisements/searchResult/listing/noListingData.svg';
import { Svg } from 'modules/theme/components/svg/Svg';

interface INoData {
    onResetFilterClick: () => void;
}

export const NoData: FC<INoData> = ({ onResetFilterClick }) => {

    return <Box
        pb={8}
        textAlign='center'
        borderBottom={(theme: Theme) => `1px solid ${theme.palette.dividerTransparent}`}
    >

        <Box mb={2}>
            <Svg component={NoDataImg} width={110} height={88} />
        </Box>

        <Box mb={.5}>
            <Text variant='h5' semibold>
                Žiadne vyhovujúce realitné kancelárie
            </Text>
        </Box>

        <Box mb={3}>
            <Text variant='body3'>
                Skúste zmeniť, prípadne odstrániť niektoré filtre, alebo upravte lokalitu hľadania.
            </Text>
        </Box>

        <Button
            tertiary
            text='Resetovať filter'
            onClick={onResetFilterClick}
        />
    </Box>;
};
