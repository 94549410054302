import { FC } from 'react';
import { Box } from '@mui/material';
import { NoData } from 'components/page/realEstate/agency/listing/components/search/result/listing/noResult/noData/NoData';
import { Related } from 'components/page/realEstate/agency/listing/components/search/result/listing/noResult/related/Related';

interface INoResult {
    onResetFilterClick: () => void;
    locationIds: string[];
    showRelated: boolean;
}

export const NoResult: FC<INoResult> = ({
    onResetFilterClick,
    locationIds,
    showRelated,
}) => {
    return <>
        <Box>
            <NoData onResetFilterClick={onResetFilterClick}/>
        </Box>

        {showRelated && (
            <Box>
                <Related locationIds={locationIds} />
            </Box>
        )}
    </>;
};
