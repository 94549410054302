import { FC } from 'react';
import { Grid } from '@mui/material';
import { ETransactionTypes } from 'modules/theme/components/agency/common/enums/ETransactionTypes';
import { AgentsInfo } from 'modules/theme/components/agency/agency/listingCard/partials/agentsInfo/AgentsInfo';
import {
    AgencyDetailLinkWrapper
} from 'modules/theme/components/agency/agency/common/components/AgencyDetailLinkWrapper';
import {
    ListingCardWrapper as AgencyListingCardWrapper
} from 'modules/theme/components/agency/common/components/wrapper/ListingCardWrapper';
import {
    AgencyInfo as AgencyBaseInfo
} from 'modules/theme/components/agency/agency/listingCard/partials/agencyInfo/AgencyInfo';
import {
    INumberOfTotalAdvertisementsInfo
} from 'modules/theme/components/agency/common/interfaces/INumberOfTotalAdvertisementsInfo';
import {
    GroupPhotos
} from 'modules/theme/components/agency/common/components/advertisementsInfo/photos/groupPhotos/GroupPhotos';
import {
    NumberOfAdvertisementsInfo
} from 'modules/theme/components/agency/common/components/advertisementsInfo/counts/NumberOfAdvertisementsInfo';
import { IAdvertisementsPhoto } from 'modules/theme/components/agency/common/interfaces/IAdvertisementsPhoto';

interface IAgencyListingCard {
    city?: string;
    street?: string;
    region?: string;
    isProfi?: boolean;
    agencyName: string;
    streetNumber?: string;
    agencyDetailUrl: string;
    agencyLogoPreviewImgUrl?: string;
    agentsPreviewPhotosUrl?: string[];
    numberOfTotalActiveAgents?: number;
    advertisementsPhotos?: IAdvertisementsPhoto[];
    numberOfTotalAdvertisementForRent?: number;
    numberOfTotalAdvertisementForSale?: number;
    onClick?: () => void;
}

export const AgencyListingCard: FC<IAgencyListingCard> = ({
    city,
    street,
    region,
    isProfi,
    agencyName,
    streetNumber,
    agencyDetailUrl,
    advertisementsPhotos,
    agentsPreviewPhotosUrl,
    agencyLogoPreviewImgUrl,
    numberOfTotalActiveAgents,
    numberOfTotalAdvertisementForRent,
    numberOfTotalAdvertisementForSale,
    onClick,
}) => {

    const showAdvertisementPhotos = isProfi;

    const numberOfAdvertisementsInfo: INumberOfTotalAdvertisementsInfo[] = [
        { transactionType: ETransactionTypes.SALE, numberOfTotal: numberOfTotalAdvertisementForSale },
        { transactionType: ETransactionTypes.RENT, numberOfTotal: numberOfTotalAdvertisementForRent }
    ];

    return (
        <AgencyDetailLinkWrapper detailUrl={agencyDetailUrl} onClick={onClick}>
            <AgencyListingCardWrapper>

                <Grid container gap={3}>

                    <Grid container alignItems='end' gap={{ xs: 3, md: 0 }} item xs={12}>
                        <Grid item xs={12} md={7} lg={8}>
                            <AgencyBaseInfo
                                city={city}
                                street={street}
                                region={region}
                                isProfi={isProfi}
                                agencyName={agencyName}
                                streetNumber={streetNumber}
                            />
                        </Grid>

                        <Grid item xs={12} md={5} lg={4}>
                            <NumberOfAdvertisementsInfo
                                previewImgUrl={agencyLogoPreviewImgUrl}
                                numberOfTotalAdvertisementsInfo={numberOfAdvertisementsInfo}
                            />
                        </Grid>
                    </Grid>

                    {showAdvertisementPhotos &&
                        <Grid container item xs={12}>
                            <GroupPhotos advertisementsPhotos={advertisementsPhotos}/>
                        </Grid>
                    }

                    <Grid container item xs={12}>
                        <AgentsInfo
                            agentsPreviewPhotosUrl={agentsPreviewPhotosUrl}
                            numberOfTotalActiveAgents={numberOfTotalActiveAgents}
                        />
                    </Grid>
                </Grid>

            </AgencyListingCardWrapper>
        </AgencyDetailLinkWrapper>
    );
};
