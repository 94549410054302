import { FC } from 'react';
import { Box } from '@mui/material';
import { ListOfAgencies } from 'components/page/realEstate/agency/listing/components/search/result/listing/listOfAgencies/ListOfAgencies';
import { useRelatedAgencies } from 'components/page/realEstate/agency/listing/hooks/related/useRelatedAgencies';
import { Text } from 'modules/theme/components/text/Text';
import { RELATED_LIMIT } from 'components/page/realEstate/common/advertisements/constants/limit';

interface IRelatedProps {
    locationIds: string[];
}

export const Related: FC<IRelatedProps> = ({ locationIds }) => {
    const { agencies, isFetching } = useRelatedAgencies(locationIds);

    if (isFetching) {
        return null;
    }

    if (agencies.length === 0) {
        return null;
    }

    return <Box pt={5}>
        <Box mb={2}>
            <Text variant='h5' center semibold>
                Podobné vášmu hľadaniu
            </Text>
        </Box>
        <ListOfAgencies agencies={agencies} currentPage={1} limit={RELATED_LIMIT}/>
    </Box>;
};
